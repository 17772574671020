<template>
  <div class="w-full bg-white shadow-lg rounded-lg relative p-4">
    <div class="text-xl font-bold text-gray-800">{{ event.theme }}</div>
    <div class="text-lg text-gray-400">{{ event.pastorName }}</div>
    <div class="flex justify-between">
      <div class="text-lg text-red-400">{{ time }}</div>
      <div class="text-lg text-gray-400">{{ date }}</div>
    </div>
    <div v-if="isKids" class="flex space-x-2">
      <div class="text-base text-gray-800 w-3/12">Kelas</div>
      <div class="text-base text-gray-800">:</div>
      <div class="text-base text-gray-800">{{ event.className }}</div>
    </div>
    <div
      v-if="isPublic && completeInformation"
      class="flex flex-col my-3">
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Penatua Pendamping</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.pastorAssistantName }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Penatua Penyambut</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.greeterNames }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Paduan Suara</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.choirName }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Pemandu Musik</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.chantingGuideName }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Pemusik</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.bandName }}</div>
      </div>
    </div>
    <div
      v-if="isTeen && completeInformation"
      class="flex flex-col my-3">
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Info</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.info }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Penatua Pendamping</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.pastorAssistantName }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Pemusik</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.bandName }}</div>
      </div>
      <div class="flex space-x-2">
        <div class="text-base text-gray-800 w-3/12">Singer</div>
        <div class="text-base text-gray-800">:</div>
        <div class="text-base text-gray-800">{{ event.singerName }}</div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <div class="text-blue-500 cursor-pointer">Mengikuti Kebaktian</div>
      <div
        v-if="!isKids"
        @click="toggleCompleteInformation"
        class="text-blue-500 cursor-pointer">{{ moreInfoLabel }}</div>
    </div>
  </div>
</template>

<script>
import { timeFormater, dateFormater } from '@/utils/time'
import { scheduleConfig } from '@/config'

export default {
  name: 'ScheduleCard',
  data () {
    return {
      completeInformation: false
    }
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    time () {
      return timeFormater(this.event.eventDate)
    },
    date () {
      return dateFormater(this.event.eventDate)
    },
    moreInfoLabel () {
      return this.completeInformation ? 'Sembunyikan' : 'Selengkapnya'
    },
    eventType () {
      return this.event.type
    },
    isPublic () {
      return this.eventType === scheduleConfig.type.public
    },
    isTeen () {
      return this.eventType === scheduleConfig.type.teen
    },
    isKids () {
      return this.eventType === scheduleConfig.type.kids
    }
  },
  methods: {
    toggleCompleteInformation () {
      this.completeInformation = !this.completeInformation
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
